import React from 'react';
import moment from 'moment';
import { Modal } from 'antd';
import Cookies from 'js-cookie';
import { COOKIES_SECURED, RouteConstants } from './constants';
import apiRequest from './apiRequests';
import axios from 'axios';
//import { ride_ms } from './api';

export const api = {
  space: 'qa',
};

export const app = {
  version: require('../../package.json').version,
  dbpref: 'oya_',
  cookieDomain: process.env.REACT_APP_COOKIES_DOMAIN || 'localhost',
  adm_url: process.env.REACT_APP_ADMIN_URL || 'http://localhost:3117/',
  accounts_url: process.env.REACT_APP_ACCOUNTS_URL || 'http://localhost:3115/',
  authApi:
    process.env.REACT_APP_AUTH_API_URL || 'https://auth-ms.test.oyaghana.com/',
  hiring_url:
    process.env.REACT_APP_HIRING_URL ||
    'https://bus-hiring-ms.test.oyaghana.com',
  ride_url:
    process.env.REACT_APP_RIDE_URL || 'https://ride-ms.test.oyaghana.com/',
  api_url: process.env.REACT_APP_API_URL || 'https://api.test.oyaghana.com/api',
};

export const initialize = () => {
  api.apiURL =
    process.env.REACT_APP_API_URL || 'https://api.test.oyaghana.com/api/';
  api.parcelURL =
    process.env.REACT_APP_PARCEL_URL || 'https://parcel-ms.test.oyaghana.com';
  api.hiringURL =
    process.env.REACT_APP_HIRING_URL ||
    'https://bus-hiring-ms.test.oyaghana.com';
  api.apiToken = getStorage('token') || '';
};

export const dates = {
  yr: moment().format('YYYY'),
};

// ::: cookie
export const setCookie = (cname, cvalue, exdays = 7) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();

  document.cookie = `${app.dbpref + cname}=${cvalue};${expires};path=/;domain=${
    app.cookieDomain
  };SameSite=Strict;${COOKIES_SECURED ? 'Secure' : ''}`;
};

export const setCookieJson = (cname, cvalue, exdays = 7) => {
  setCookie(cname, JSON.stringify(cvalue), exdays);
};

export const getCookie = (cname) => {
  const name = app.dbpref + cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      if (c.split('=')[1].length !== 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return '';
};

export const getDaysUntilJwtExpiration = (jwtToken) => {
  const tokenParts = jwtToken.split('.');
  if (tokenParts.length !== 3) {
    throw new Error('Invalid JWT format');
  }

  const payload = JSON.parse(atob(tokenParts[1]));

  if (!payload.exp) {
    throw new Error('JWT does not contain an expiration claim (exp)');
  }

  const expirationTimestamp = payload.exp;
  const currentTimestamp = Math.floor(Date.now() / 1000);

  const secondsUntilExpiration = expirationTimestamp - currentTimestamp;
  const daysUntilExpiration = Math.ceil(
    secondsUntilExpiration / (60 * 60 * 24)
  );

  return daysUntilExpiration;
};

export const getCookieJson = (cname) => {
  const value = getCookie(cname);
  return value && isJson(value) ? JSON.parse(value) : '';
};
export const delCookie = (cname) => {
  console.log(`${app.dbpref + cname}`);
  Cookies.remove(`${app.dbpref + cname}`, {
    path: '/',
    domain: app.cookieDomain,
    expires: -1,
  });
  // const expires = "expires=-1";
  // document.cookie = `${app.dbpref + cname}=;${expires};path=/;domain=${
  //   app.cookieDomain
  // }`;
};

export const stringToBase64 = (str) => {
  const base64String = btoa(str);
  return base64String;
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

// ::: storage
export const setStorage = (key, value) => {
  if (key && value) {
    localStorage.setItem(app.dbpref + key, value);
  }
};
export const getStorage = (key) => {
  const value = localStorage.getItem(app.dbpref + key);
  return value || '';
};
export const setStorageJson = (key, value) => {
  if (key && value) {
    localStorage.setItem(app.dbpref + key, JSON.stringify(value));
  }
};
export const getStorageJson = (key) => {
  if (key) {
    const value = localStorage.getItem(app.dbpref + key);
    return JSON.parse(value) || [];
  }
};
export const delStorage = (key) => {
  if (key) {
    localStorage.removeItem(app.dbpref + key);
  }
};

// Data Request
initialize();

// Spinners
export const fspinner = (
  <div style={{}}>
    <i className='fa fa-spin fa-spinner fa-5x primary'></i>
  </div>
);
export const fspinnerSm = (
  <div style={{}}>
    <i className='fa fa-spin fa-spinner fa-3x primary'></i>
  </div>
);
export const fspinnerXs = <i className='fa fa-spin fa-spinner primary'></i>;

export const redirect = (to) => {
  window.location = to;
};

export const generateOptions = (length, step = 1) => {
  const arr = [];
  for (let value = 0; value < length; value += step) {
    arr.push(value);
  }
  return arr;
};

export const hasR = (role) => {
  return true;
  // let user = getStorageJson('user');
  // let myRoles = ((user.role || {}).data || '').split(',');
  // return (myRoles.includes(role) || myRoles.includes('*'));
};

export const numberFormat = (number, minimumFractionDigits = 0) => {
  return new Intl.NumberFormat('en-US', { minimumFractionDigits }).format(
    number
  );
};

export const loading = (content) => {
  return new Promise((resolve) => {
    content = (
      <div
        dangerouslySetInnerHTML={{
          __html: `<i class="fa fa-spin fa-spinner primary"></i> <span>${content}</span>`,
        }}
      />
    );
    const loading = Modal.info({
      icon: null,
      title: null,
      centered: true,
      content,
      width: '250px',
      cancelText: <div />,
    });
    resolve(loading);
  });
};

export const alert = (props = {}) => {
  Modal.confirm({
    okText: 'Okay!',
    width: '300px',
    cancelText: <span />,
    cancelButtonProps: { className: 'hide' },
    ...props,
    icon: null,
    centered: true,
    content: <span dangerouslySetInnerHTML={{ __html: props.content }} />,
    // onOk: (close) => {
    //     close();
    //     props.onOK && props.onOK();
    // }
  });
};

export const datesBetween = (startDate, endDate, format = 'YYYY-MM-DD') => {
  const dates = [];
  let currentDate = startDate;
  const addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  while (currentDate <= endDate) {
    const date = moment(currentDate).format(format);
    if (!dates.includes(date)) {
      dates.push(date);
    }
    currentDate = addDays.call(currentDate, 1);
  }
  return dates;
};

export const getBase64 = (img, callback, type = 'dataUrl') => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  if (type === 'dataUrl') reader.readAsDataURL(img);
  if (type === 'arrayBuffer') reader.readAsArrayBuffer(img);
};

const signOut = () => {
  delCookie('user');
  delCookie('token');
  delCookie('profile');
  delCookie('role');
  delCookie('appref');

  redirect(
    app.accounts_url + 'login' + '?appref=' + window.location.origin + '/'
  );
};

export const requestInterceptor = (config) => {
  const token = Cookies.get('oya_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers['content-type'] = 'application/json';

  // if (!token) {
  //   signOut()
  // }
  if (
    !token &&
    ![
      '/ticket',
      '/delegates-overview',
      '/delegates-enroll',
      '/delegates-signup',
      '/delegates-signin',
    ].includes(location.pathname)
  ) {
    signOut();
  }

  return config;
};

export const responseInterceptor = (response) => {
  if (
    (response && response.status === 401) ||
    (response &&
      (response?.data?.status === 401 || response?.data?.code === 401))
  ) {
    if (
      [
        '/ticket',
        '/delegates-overview',
        '/delegates-enroll',
        '/delegates-signup',
        '/delegates-signin',
      ].includes(location.pathname)
    ) {
      return;
    } else {
      signOut();
    }
  }

  return response;
};

export const convertPhoneNumberToInternational = (number, countryCode) => {
  let cleanedNumber = number.replace(/\D/g, '');

  if (cleanedNumber.startsWith(countryCode)) {
    return cleanedNumber;
  } else {
    const phone = cleanedNumber?.slice(1);
    return `${countryCode}${phone}`;
  }
};

//check transaction status
export const getTransactionStatus = async (id) => {
  //console.log(url);
  const maxAttempts = 10;

  for (let attempt = 1; attempt <= maxAttempts; attempt++) {
    try {
      // console.log(`Attempt ${attempt}`);
      const response = await apiRequest.get(
        `${RouteConstants.TRANSACTIONS}/${id}`
      );

      const statusCode = response?.data?.payload?.status?.code;
      if (statusCode === 'SUCCESS' || statusCode === 'FAILED') {
        return response;
      }

      // Wait for a while before the next attempt
      await new Promise((resolve) => setTimeout(resolve, 5000)); // 15 seconds
    } catch (error) {
      // Handle error if needed
      return error;
    }
  }

  // If not successful after 15 attempts, return an error or handle it accordingly
  return { error: 'Max attempts reached, transaction status not updated.' };
};

export const validateEmail = (rule, value, callback) => {
  // Regular expression for basic email validation
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  if (!value || emailRegex.test(value)) {
    callback();
  } else {
    callback('Please enter a valid email address');
  }
};
