import React, { memo } from 'react'
import PropTypes from 'prop-types'

const Loader = ({
  float = false,
  ready = false,
  bgColor = 'bg-black/5',
  width = 'w-20',
  height = 'h-20'
}) => {
  return (
    <div
      className={`text-oya-ghana-red flex justify-center items-center w-full h-full ${
        float ? 'absolute inset-0' : ''
      } ${bgColor} ${ready ? 'disappear' : ''}`}
    >
      <div
        className={`${width} ${height} animate-spin appear border-2 border-[#c8c9ca] border-t-gray-900 rounded-full ${
          ready && 'disappear'
        }`}
      />
    </div>
  )
}

Loader.propTypes = {
  float: PropTypes.bool,
  ready: PropTypes.bool,
  bgColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
}

export default memo(Loader)
