import axios from "axios";
import {
  APP_API_URL,
  AUTH_MS_URL,
  HIRING_MS_URL,
  PARCEL_MS_URL,
  RIDE_MS_URL,
} from "./constants";
import { getCookie } from "./functions";

export const apiHeaders = (type = "") => {
  const token = getCookie("token") || "";
  if (type === "file") {
    return {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
  } else {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  }
};

export const get = async (path, params = {}, options = {}) => {
  let headers = apiHeaders();
  headers = { ...headers, ...options };
  const url = `${APP_API_URL}${path}`;
  return axios({
    method: "GET",
    url,
    headers,
    params,
  })
    .then((response) => {
      const res = response.data;
      return res;
    })
    .catch((error) => {
      return { status: 606, result: "Network request failed", error };
    });
};

export const bus_hiring = axios.create({
  baseURL: `${HIRING_MS_URL}`,
  headers: apiHeaders(),
});

export const ride_ms = axios.create({
  baseURL: `${RIDE_MS_URL}`,
  headers: apiHeaders(),
});

export const parcel_ms = axios.create({
  baseURL: `${PARCEL_MS_URL}`,
  headers: apiHeaders(),
});

export const auth_ms = axios.create({
  baseURL: `${AUTH_MS_URL}`,
  headers: apiHeaders(),
});
