import React from 'react'
import PropTypes from 'prop-types'
// import { func } from '../utils'
import Loader from './Loader'

const Loading = ({ text }) => {
  return (
    <React.Fragment>
      <div className="flex-middle" style={{ height: '70vh', justifyContent: 'center' }}>
        <div className="text-center">
          {/* {func.fspinnerSm} */}
          <Loader bgColor='transparent appear' width='w-16' height='h-16' />
          {/* <div className='pt-2' dangerouslySetInnerHTML={{ __html: text }} /> */}
        </div>
      </div>
    </React.Fragment>
  )
}
Loading.propTypes = {
  text: PropTypes.string
}

export default Loading
