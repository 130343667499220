/* eslint-disable multiline-ternary */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Button, Form, Input, Modal, notification, Select } from "antd";
import PropTypes from "prop-types";
import { RIDE_MS_URL } from "../utils/constants";
import { getTransactionStatus } from "../utils/functions";
import axios from "axios";
import apiRequest from "../utils/apiRequests";
import PhoneInput from "react-phone-number-input";
import { parcel_ms, ride_ms } from "../utils/api";
import { RouteConstants } from "../utils/constants";
// import OtpInput from 'react-otp-input'

const payment_providers = [
  {
    value: "MTN_GH",
    lable: "MTN (Ghana)",
  },
  {
    value: "VODAFONE_GH",
    lable: "VODAFONE (Ghana)",
  },
  {
    value: "TIGO_GH",
    lable: "AIRTEL/TIGO (Ghana)",
  },
];

const PayModal = ({
  onSuccess,
  onCancel,
  reference,
  amount,
  title,
  type = "Ticket",
  entity = [],
  paymentUrl = null,
  details = null,
}) => {
  const navigate = useNavigate;
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [paymentState, setpaymentState] = useState(null);
  const [otpRequired, setOTPRequirement] = useState(false);
  const [paymentId, setPaymentId] = useState(false);
  const [phone, setPhone] = useState(null);
  const [providers, setProviders] = useState([]);

  const getProviders = async () => {
    const { data } = await axios.get(`${RIDE_MS_URL}/v2/providers`);
    setProviders(data?.payload?.items);
  };

  const close = () => {
    setSubmitting(false);
    form.resetFields();
    onCancel();
  };

  const submitOtp = async (values) => {
    try {
      setSubmitting(true);
      const payload = {
        credential: `${values?.otp}`,
      };
      const { data } = await ride_ms.post(
        `${RouteConstants.TRANSACTIONS}/${paymentId}/authorize`,
        {
          ...payload,
        }
      );

      //setpaymentState(data.message);
      notification.success({
        message: "OTP Verified",
        description: data.message,
        duration: 15,
      });
      //close();

      // setTimeout(() => {
      //   close();
      //   setpaymentState('');
      //   onSuccess(data.data);
      // }, 15000);
      //navigate(-1, { replace: true });
      getTransactionStatus(paymentId)
        .then((paymentRes) => {
          const code = paymentRes?.data?.payload?.status?.code;
          if (code === "SUCCESS") {
            close();
            notification.success({
              message: "Payment Successful",
            });
            onSuccess(paymentRes?.data?.payload);
            // loadingState();
          } else if (code === "FAILED") {
            close();
            notification.error({
              message: "Payment Failed or Cancelled",
            });
            // loadingState();
          } else {
            close();
            // loadingState();
          }
        })
        .catch((e) => {
          console.log(e);
          notification.error({
            message: e?.response?.data?.message,
          });
          close();
          //loadingState();
        });
      setSubmitting(false);
    } catch (e) {
      console.log(e);
      setSubmitting(false);
      notification.error({ message: e?.response?.data?.message });
      setpaymentState("");
      //navigate(-1, { replace: true });
    }
  };

  const submit = async (v) => {
    setSubmitting(true);

    //console.log(v);

    const paymentData = {
      type: "DEBIT",
      description: `${type} purchase`,
    };

    let services = entity?.map((tic) => {
      return tic?.id ?? "N/A";
      // return {
      //   service_id: tic?.id ?? "N/A",
      //   service_code: type?.toUpperCase(),
      // };
    });

    if (paymentUrl && type === "Parcel") {
      //paymentData.account_provider = 'MTN';
      paymentData.method = "MOBILE_MONEY";
      services = entity;

      try {
        const res = await parcel_ms.post(RouteConstants.BUS_PAYMENTS, {
          ...v,
          ...paymentData,
          services,
          account_number: phone,
        });

        if (res?.data?.code >= 404) {
          notification.error({
            message: res?.data?.message,
          });
          close();
        } else {
          setPaymentId(res?.data?.payload?.id);
          if (res?.data?.payload?.auth?.type === "OTP") {
            setOTPRequirement(true);
          } else {
            getTransactionStatus(res.data?.payload?.id)
              .then((paymentRes) => {
                notification.info({
                  message: "Payment Initiated",
                  duration: 15,
                });

                const code = paymentRes?.data?.payload?.status?.code;
                if (code === "SUCCESS") {
                  close();
                  notification.success({
                    message: "Payment Successful",
                  });
                  onSuccess(paymentRes?.data?.payload);
                  // loadingState();
                } else if (code === "FAILED") {
                  close();
                  notification.error({
                    message: "Payment Failed or Cancelled",
                  });
                  // loadingState();
                } else {
                  close();
                  // loadingState();
                }
              })
              .catch((e) => {
                console.log(e);
                notification.error({
                  message: e?.response?.data?.message || e?.message,
                });
                close();
                //loadingState();
              });
          }
        }

        setSubmitting(false);
      } catch (e) {
        setSubmitting(false);
        notification.error({
          message: e?.response?.data?.message || e?.message,
        });
      }
    } else {
      apiRequest
        .post(
          RouteConstants.BUS_PAYMENTS,
          {
            ...v,
            ...paymentData,
            services,
            account_number: phone,
            method: v.account_provider,
          },
          !!paymentUrl
        )
        .then(
          (res) => {
            console.log(res.data);
            //setCallBackUrl(res.data?.payload?.callback_url);
            setPaymentId(res?.data?.payload?.id);
            if (res?.data?.payload?.auth?.type === "OTP") {
              setOTPRequirement(true);
            } else {
              //setpaymentState(res.message);
              notification.info({
                message: "Payment Initiated",
                description: res.data?.message,
                duration: 15,
              });
              // close();

              // setTimeout(() => {
              //   close();
              //   setpaymentState('');

              //   //redirect('trips')
              //   //navigate("/trips", { replace: true });
              //   onSuccess(res.data);
              // }, 15000);
              getTransactionStatus(res.data?.payload?.id)
                .then((paymentRes) => {
                  console.log(paymentRes);
                  const code = paymentRes?.data?.payload?.status?.code;
                  if (code === "SUCCESS") {
                    close();
                    notification.success({
                      message: "Payment Successful",
                    });
                    onSuccess(paymentRes?.data?.payload);
                    // loadingState();
                  } else if (code === "FAILED") {
                    close();
                    notification.error({
                      message: "Payment Failed or Cancelled",
                    });
                    // loadingState();
                  } else {
                    notification.error({
                      message: "Payment Failed or Cancelled",
                    });
                    close();
                    // loadingState();
                  }
                })
                .catch((e) => {
                  console.log(e);
                  notification.error({
                    message: e?.response?.data?.message,
                  });
                  close();
                  //loadingState();
                });
            }
            // setSubmitting(false);
          }

          // () => {
          //   notification.error({ message: "Payment Failed" });
          // }
        )
        .catch((error) => {
          const message = error.response?.data?.message || error.message;
          setSubmitting(false);
          notification.error({ message: message });
        });
    }
  };

  useEffect(() => {
    if (details) {
      const code = details?.payment_status?.code || details?.status?.code;
      if (code === "PROCESSING") setOTPRequirement(true);
    }
  }, [details]);

  useEffect(() => {
    getProviders();
  }, []);

  return (
    <>
      <Modal
        visible={!!(reference && amount)}
        centered
        closable={false}
        title={
          paymentState ? null : (
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center items font-bold text-oya-ghana-header-h5 pr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="34"
                  viewBox="0 0 35 34"
                  fill="none"
                  className="mr-2"
                >
                  <path
                    d="M34.007 10.5198L32.936 9.45097C32.4696 8.94703 31.7451 8.77296 31.1 9.00987C30.5605 9.22936 29.9825 9.33895 29.4 9.33222C27.0528 9.33222 25.15 7.43329 25.15 5.09085C25.1432 4.5095 25.2531 3.93267 25.473 3.3943C25.7417 2.73127 25.5649 1.97125 25.031 1.49417L23.96 0.425347C23.6327 0.136633 23.2065 -0.0152489 22.77 0.00121071H20.764C20.318 0.00308476 19.8906 0.179782 19.574 0.493209L0.993 19.0365C0.678935 19.3525 0.501878 19.779 0.5 20.224V22.226C0.501878 22.671 0.678935 23.0976 0.993 23.4136L2.064 24.4824C2.53036 24.9863 3.25492 25.1604 3.9 24.9235C4.43946 24.704 5.01747 24.5944 5.6 24.6011C7.94721 24.6011 9.85 26.5001 9.85 28.8425C9.85675 29.4238 9.74693 30.0007 9.527 30.539C9.25826 31.2021 9.43506 31.9621 9.969 32.4392L11.04 33.508C11.3566 33.8214 11.784 33.9981 12.23 34H14.236C14.682 33.9981 15.1094 33.8214 15.426 33.508L34.007 14.8969C34.3211 14.5809 34.4981 14.1544 34.5 13.7093V11.7074C34.4981 11.2623 34.3211 10.8358 34.007 10.5198ZM17.5 20.3598C15.6222 20.3598 14.1 18.8406 14.1 16.9667C14.1 15.0927 15.6222 13.5736 17.5 13.5736C19.3778 13.5736 20.9 15.0927 20.9 16.9667C20.9 18.8406 19.3778 20.3598 17.5 20.3598Z"
                    fill="#7C0000"
                  />
                </svg>
                <span className="text-black">{title}</span>:
              </div>

              <div>#{reference}</div>
            </div>
          )
        }
        footer={null}
      >
        {otpRequired ? (
          <Form
            form={form}
            layout="vertical"
            onFinish={submitOtp}
            className="appear"
          >
            <Form.Item
              colon={false}
              label="OTP"
              name="otp"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input size="large" disabled={submitting} placeholder="000000" />
              {/* <div className="flex flex-row items-center justify-between w-full">
                <OtpInput
                  // value={code}
                  // onChange={handleChange}
                  numInputs={6}
                  shouldAutoFocus
                  inputStyle="!w-[100%] text-2xl lg:text-4xl font-bold p-0 h-[80%] border-none text-black bg-transparent !ring-0"
                  className="w-16 h-16 flex items-center justify-center text-center px-3 outline-none rounded-xl border border-gray-200 text-lg bg-white bg-default mr-4"
                />
                </div> */}
            </Form.Item>
            <div className="text-right">
              {/* <Button
                    size="large"
                    danger
                    onClick={close}
                    className="mr-5"
                    disabled={submitting}
                  >
                    &nbsp; &nbsp; &nbsp; Cancel &nbsp; &nbsp; &nbsp;{' '}
                  </Button> */}
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={submitting}
              >
                &nbsp; &nbsp; &nbsp; Pay &nbsp; &nbsp; &nbsp;{" "}
              </Button>
            </div>
          </Form>
        ) : (
          <>
            {paymentState ? (
              <div className="w-full text-center appear">
                <svg
                  id="success"
                  className="mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  width="150"
                  height="150"
                  viewBox="0 0 150 150"
                  fill="none"
                >
                  <path
                    d="M75 150C33.5775 150 0 116.423 0 75C0 33.5775 33.5775 0 75 0C116.423 0 150 33.5775 150 75C150 116.423 116.423 150 75 150ZM67.5225 105L120.548 51.9675L109.943 41.3625L67.5225 83.79L46.305 62.5725L35.7 73.1775L67.5225 105Z"
                    fill="#7C0000"
                  />
                </svg>
                <div className="pt-10">
                  <h2 className="font-medium text-oya-ghana-header-h6 md:text-oya-ghana-header-h5">
                    Payment Initiated!
                  </h2>
                </div>
              </div>
            ) : (
              <Form
                form={form}
                layout="vertical"
                onFinish={submit}
                className="appear"
              >
                <Form.Item
                  colon={false}
                  label="Choose Payment Network"
                  initialValues="mtn"
                  name="account_provider"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Select
                    autoComplete="off"
                    size="large"
                    disabled={submitting}
                    placeholder="Network"
                  >
                    {payment_providers.map((net, index) => (
                      <Select.Option key={index} value={net?.value}>
                        {net?.lable}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  colon={false}
                  label="Mobile Money Number"
                  name="account_number"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  {/* <Input
                    size="large"
                    disabled={submitting}
                    placeholder="+233244123456"
                  /> */}
                  <PhoneInput
                    value={phone}
                    onChange={(e) => {
                      let formatted = e?.replace(/\s+/g, "");
                      setPhone(formatted);
                    }}
                    placeholder="0244123456"
                    className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 focus:border-gray-200 focus:outline-none"
                    defaultCountry="GH"
                    disabled={submitting}
                  />
                </Form.Item>

                <Form.Item
                  colon={false}
                  label="Mobile Money Name"
                  initialValues="Bernard Danso"
                  name="account_name"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input
                    size="large"
                    disabled={submitting}
                    placeholder="Full name"
                  />
                </Form.Item>

                <div className="text-right">
                  <Button
                    size="large"
                    danger
                    onClick={close}
                    className="mr-5"
                    disabled={submitting}
                  >
                    &nbsp; &nbsp; &nbsp; Cancel &nbsp; &nbsp; &nbsp;{" "}
                  </Button>

                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={submitting}
                  >
                    &nbsp; &nbsp; &nbsp; Pay &nbsp; &nbsp; &nbsp;{" "}
                  </Button>
                </div>
              </Form>
            )}
          </>
        )}
      </Modal>
    </>
  );
};
PayModal.propTypes = {
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  reference: PropTypes.string,
  amount: PropTypes.number,
  title: PropTypes.string,
  type: PropTypes.string,
  entity: PropTypes.array,
  paymentUrl: PropTypes.string,
  details: PropTypes.object,
  showModal: PropTypes.bool,
};
export default PayModal;
