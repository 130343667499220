// Auth
export const SIGNING_IN = 'SIGNING_IN'
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'

// Utils
export const SET_SITE_LANG = 'SET_SITE_LANG'
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE'
export const SET_PAGE_SUB_TITLE = 'SET_PAGE_SUB_TITLE'
export const SET_SET_SETTINGS = 'SET_SET_SETTINGS'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
