// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseUrl: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGUB_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    const firebaseCon = encodeURIComponent(JSON.stringify(firebaseConfig))
    navigator.serviceWorker
      .register(
        `../../../public/firebase-messaging-sw.js?firebaseConfig=${firebaseCon}`
      )
      .then(function (registration) {
        console.log('Registration successful, scope is:', registration.scope)
      })
      .catch(function (err) {
        console.log('Service worker registration failed, error:', err)
      })
  }
}

export const requestPermission = () =>
  new Promise((resolve, reject) => {
    return getToken(messaging, {
      vapidKey: process.env.REACT_APP_FCM_SERVER_KEY
    })
      .then((currentToken) => {
        if (currentToken) {
          resolve(currentToken)
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        reject(err)
      })
  })

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload)
    })
  })
